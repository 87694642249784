$rhap_theme-color: $blue-600;
.dock-layout {
  transition: all 0.3s ease;
}
.rhap_container {
  .rhap_progress-section {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 8px;
    .rhap_progress-container {
      margin: 0 0 0 10px;
      .rhap_progress-bar {
        .rhap_progress-indicator {
          top: -6px;
          width: 15px;
          height: 15px;
          background-color: $rhap_theme-color;
        }
        .rhap_progress-filled {
          left: -8px;
          border-radius: 0;
          background-color: rgba($rhap_theme-color, 0.75) !important;
        }
        .rhap_download-progress {
          border-radius: 0;
        }
      }
    }
  }
  .rhap_controls-section {
    margin-top: 0 !important;
    .rhap_main-controls-button {
      color: $rhap_theme-color;
    }
    .rhap_volume-controls {
      flex-grow: 0;
      margin: 0 1.2rem 0 0.5rem;

      .rhap_volume-bar-area {
        width: 50px;
      }
      .rhap_volume-indicator {
        background: $rhap_theme-color;
        &:before {
          width: 100%;
          height: 4px;
          background-color: rgba($rhap_theme-color, 0.75) !important;
        }
      }
    }
    .rhap_current-time,
    .rhap_time-slash,
    .rhap_total-time {
      font-size: 0.8rem;
    }

    .rhap_additional-controls {
      flex-grow: 0;
    }
    .rhap_other_controls {
      margin-left: auto;
    }
  }
}

.rhap_hidden {
  bottom: -60px !important;
  .rhap_progress-indicator {
    display: none;
  }
  .rhap_progress-section {
    width: 102%;
    left: -2px;
    .rhap_progress-filled {
      background-color: $rhap_theme-color !important;
    }
  }
}
